import styled from '@emotion/styled';
import { ReactComponent as Mobile } from '../../images/svgs/mobile.svg';
import { ReactComponent as Logo } from '../../images/svgs/logo-nav.svg';
import { ReactComponent as Arrow } from '../../images/svgs/arrow-nav.svg';
import { ReactComponent as DropdownArrow } from '../../images/svgs/dropdown-arrow.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonPrimary } from './StyledComponents';
import { navMenu } from './navMenu';
import { Link } from 'gatsby';
import { useSiteContext } from '../../layouts/LayoutProvider';
import { information } from 'information';

const NavContainer = styled.nav<NavProps>`
    position: fixed;
    top: 0;
    background-color: ${({ scroll }) => (scroll ? 'white' : 'white')};
    width: 100vw;
    z-index: 3;
    button {
        a {
            color: ${({ theme }) => theme.colors.blue3};
            transition: color 0.2s ease;
        }
        &:hover {
            a {
                color: ${({ theme }) => theme.colors.blue4};
            }
        }
    }

    @media ${({ theme }) => theme.device.laptop} {
        transition: background-color 0.2s ease;
        background-color: ${({ scroll }) => (scroll ? 'white' : 'transparent')};
    }
`;

interface NavProps {
    scroll: boolean;
}

const NavWrapper = styled.main`
    display: flex;
    align-items: center;
    position: relative;
    height: 100px;
    width: auto;
    padding: 0 16px;
    justify-content: space-between;
    .logo {
        z-index: 3;
        position: relative;
        width: 85px;
    }
    svg {
        z-index: 1;
        fill: ${({ theme }) => theme.colors.blue3};
    }
    @media ${({ theme }) => theme.device.mobileL} {
        padding: 0 32px;
    }
    @media ${({ theme }) => theme.device.laptop} {
        .logo {
            width: 156px;
            position: relative;
            right: 50px;
        }
    }
`;

const MobileIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 88px;
    a {
        position: relative;
        top: 2px;
    }
    @media ${({ theme }) => theme.device.laptop} {
        display: none;
    }
`;

const Burger = styled.button`
    display: inline-flex;
    flex-direction: column;
    align-items: end;
    z-index: 2000;
    div {
        margin: 2px 0px;
        height: 3px;
        border-radius: 10px;
        background: ${({ theme }) => theme.colors.blue3};
    }

    .line-1 {
        transition: 0.2s cubic-bezier(0.1, 0.7, 0.6, 0.9);
        position: relative;
        top: ${({ active }) => (!active ? '0' : '8px')};
        transform: ${({ active }) => (!active ? 'rotate(0%)' : 'rotate(45deg)')};
        width: ${({ active }) => (!active ? '24px' : '24px')};
    }
    .line-2 {
        width: ${({ active }) => (!active ? '18px' : '0px')};
        transition: 0.5s cubic-bezier(0.1, 0.7, 0.6, 0.9);
    }
    .line-3 {
        transition: 0.2s cubic-bezier(0.1, 0.7, 0.6, 0.9);
        position: relative;
        top: ${({ active }) => (!active ? '0' : '-6px')};
        bottom: ${({ active }) => (!active ? '22' : '9px')};
        transform: ${({ active }) => (!active ? 'rotate(0%)' : 'rotate(-45deg)')};
        width: ${({ active }) => (!active ? '23px' : '24px')};
    }
`;

const DesktopIcons = styled.div`
    display: none;

    @media ${({ theme }) => theme.device.laptop} {
        display: block;
        .desktop-button-container {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            align-items: center;
        }
        .mobile-button {
            background: ${({ theme }) => theme.colors.blue3};
            width: 96px;
            box-sizing: border-box;
            height: 56px;
            cursor: pointer;
            border-radius: 50px;
            gap: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.2s ease-in-out;
            display: relative;
            .mobile-button-menu {
                position: absolute;
                width: 329px;
                box-sizing: border-box;
                right: 36px;
                display: flex;
                justify-content: center;
                border-radius: 15px;
                overflow: hidden;
                background: ${({ theme }) => theme.colors.gray1};
                top: 82px;
                box-shadow: 0px 51px 125px rgba(109, 106, 93, 0.09),
                    0px 18.5323px 39.5627px rgba(109, 106, 93, 0.0393285),
                    0px 8.27833px 15.9688px rgba(109, 106, 93, 0.0425114),
                    0px 4.0063px 6.7726px rgba(109, 106, 93, 0.0304631);
                ul {
                    padding: 0;
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    li:first-of-type {
                        position: relative;
                        right: 2px;
                    }
                    li {
                        width: 329px;
                        transition: all 0.2s ease-in-out;
                        padding: 12px;
                        &:hover {
                            background: ${({ theme }) => theme.colors.blue1};
                        }
                    }
                }
            }

            .mobile-svg {
                fill: ${({ theme }) => theme.colors.gray1};
                transition: all 0.2s ease-in-out;
            }
            .dropdown-svg {
                width: 11px;
                fill: ${({ theme }) => theme.colors.gray1};
                transition: all 0.2s ease-in-out;
            }
            :hover {
                background: ${({ theme }) => theme.colors.blue4};
                .mobile-svg {
                    transform: rotate(10deg);
                }
            }
        }
    }
`;

interface MenuProps {
    active: boolean;
    scroll: boolean;
}

const DesktopMenuContainer = styled.div<MenuProps>`
    display: none;

    @media ${({ theme }) => theme.device.laptop} {
        display: flex;
        transition: box-shadow 0.2s ease-in;
        transition: background-color 0.2s ease-in;
        justify-content: space-around;
        margin: 0px 0px 0px;
        .main-menu {
            display: flex;
            width: 470px;
            justify-content: space-between;
            list-style: none;
            > li {
                cursor: pointer;
                display: flex;
                align-items: center;
                transition: 0.2s ease-in;
                a {
                    text-decoration: none;
                    color: ${({ theme }) => theme.colors.gray5};
                }
                svg {
                    height: 8px;
                    fill: ${({ theme }) => theme.colors.gray5};
                    margin-left: 10px;
                    transition: 0.2s ease-in;
                }
                &:hover {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
            > li {
                display: inline-block;
                position: relative;
            }
            > li:hover:after {
                transform: scaleX(1);
                transform-origin: left right;
            }
            > li:after {
                content: '';
                position: absolute;
                width: 50%;
                transform: scaleX(0);
                height: 2px;
                bottom: -3px;
                left: 25%;
                background-color: ${({ theme }) => theme.colors.blue3};
                transform-origin: left right;
                transition: transform 0.25s cubic-bezier(0.33, 1, 0.68, 1);
            }
        }
    }
`;

interface MenuSecondaryProps {
    active: boolean;
    menuName: string | null;
}

const DesktopMenu = styled.ul<MenuSecondaryProps>`
    position: absolute;
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.gray1};
    width: ${({ menuName, active }) =>
        active && menuName?.toLowerCase() === 'procedures' ? '630px' : '315px'};
    overflow: hidden;
    opacity: 1;
    max-height: 360px;
    flex-wrap: wrap;
    z-index: 2;
    box-shadow: 0px 51px 125px rgba(109, 106, 93, 0.09),
        0px 18.5323px 39.5627px rgba(109, 106, 93, 0.0393285),
        0px 8.27833px 15.9688px rgba(109, 106, 93, 0.0425114),
        0px 4.0063px 6.7726px rgba(109, 106, 93, 0.0304631);
    border-radius: 15px;
    list-style: none;
    left: -16px;
    top: 50px;
    a {
        padding: 0px 70px 0 0;
        color: black;
        text-decoration: none;
    }

    li {
        transition: 0.2s ease-in;
        position: relative;
        right: 0px;
        width: 283px;
        z-index: 0;
        padding: 12px 16px;
        cursor: pointer;

        &:hover {
            background: ${({ theme }) => theme.colors.blue1};
        }
    }
    li:first-of-type {
        padding-top: 16px;
        cursor: pointer;
    }
    li:last-of-type {
        padding-bottom: 16px;
        cursor: pointer;
    }
    &:hover {
        opacity: 1;
    }
`;

const debounce = (func: { (): void; apply?: any }, ms: number) => {
    let timer: any;
    return (...args: any) => {
        if (!timer) {
            timer = setTimeout(() => {
                timer = undefined;
                func.apply(this, args);
            }, ms);
        }
    };
};

interface MenuCallProps {
    callActive: boolean;
}

const MobileCallContainer = styled.div<MenuCallProps>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding-top: 25vh;
    background: ${({ theme }) => theme.colors.blue1};
    transition: all 0.2s ease-in-out;
    transform: ${({ callActive }) => (callActive ? 'translateX(0)' : 'translateX(-100%)')};
    button {
        margin: 0 8px;
    }
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        p:first-of-type {
            font-weight: bold;
            color: ${({ theme }) => theme.colors.blue4};
            font-size: 20px;
        }
        p:last-of-type {
            text-align: center;
            margin: 8px 0;
            font-size: 20px;
            max-width: 325px;
        }
        button {
            margin-bottom: 32px;
        }
    }
    @media (min-width: 1030px) {
        display: none;
    }
`;

const MobileNavContainer = styled.div<MenuSecondaryProps>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: ${({ theme }) => theme.colors.blue1};
    transition: all 0.2s ease-in-out;
    transform: ${({ active }) => (active ? 'translateX(0)' : 'translateX(-100%)')};
    overflow: scroll;
    .blue-line {
        height: 1px;
        width: 100vw;
        position: absolute;
        top: 21.5vh;
        background: ${({ theme }) => theme.colors.blue3};
    }
    .back-button {
        position: absolute;
        display: flex;
        align-items: center;
        top: 17.2vh;
        font-size: 14px;
        left: 7vw;
        color: ${({ theme }) => theme.colors.blue3};
        font-weight: bold;

        svg {
            width: 12px;
            margin-right: 6px;
            transform: rotate(90deg);
            fill: ${({ theme }) => theme.colors.blue3};
        }
    }
    .menu-name {
        top: 16.2vh;
        left: 27.5vw;
        color: ${({ theme }) => theme.colors.blue4};
        font-weight: bold;
        font-size: 22px;
        position: absolute;
    }
    .main-menu-mobile {
        display: flex;
        justify-content: center;
        padding-left: 27.5vw;
        padding-top: 25vh;
        flex-direction: column;
        li {
            max-width: 230px;
            margin-bottom: 32px;
            list-style: none;
            button,
            a {
                font-weight: bold;
                color: ${({ theme }) => theme.colors.blue3};
                font-size: 18px;
                display: flex;
                align-items: center;
            }
            svg {
                width: 18px;
                position: relative;
                left: 12px;
            }
        }
    }
    @media (min-width: 1030px) {
        display: none;
    }
`;

type MenuType = {
    name: string;
    path: string;
    url?: string;
};

export const Nav: React.FC = () => {
    const { active, setActive } = useSiteContext();
    const [callActive, setCallActive] = useState(false);
    const isSSR = typeof window === 'undefined';
    const [scroll, setScroll] = useState(isSSR ? false : window.scrollY > 180);
    const [menuName, setMenuName] = useState<string | null>(null);
    const [menuState, setMenuState] = useState<MenuType[] | null>(null);
    const navRef = useRef(null);
    const navRefCall = useRef(null);

    const onScroll = useCallback(
        debounce(() => setScroll(window.scrollY > 180), 50),
        [scroll]
    );

    useEffect(() => {
        if (isSSR) return;
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    const handleNavClick = (e: { target: React.MutableRefObject<null> } | any) => {
        e.target !== navRef.current ? setActive(false) : setActive(true);
        e.target !== navRefCall.current ? setCallActive(false) : setCallActive(true);
    };

    return (
        <NavContainer ref={navRef} onMouseLeave={handleNavClick} scroll={scroll}>
            <NavWrapper>
                <MobileCallContainer callActive={callActive}>
                    <div className="item">
                        <p>Santa Clarita</p>
                        <p>18580 Via Princessa Suite 3, Santa Clarita, CA 91387</p>
                        <a href={`tel:${information.santaClarita.phone}`}>
                            <ButtonPrimary fill color="blue">
                                {' '}
                                <Mobile />
                                <span style={{ marginLeft: '11px' }}>SCV - (661) 592-1041</span>
                            </ButtonPrimary>
                        </a>
                    </div>
                    <div className="item">
                        <p>Porter Ranch</p>
                        <p>11239 Tampa Ave #208 Porter Ranch, CA 91326</p>
                        <a href={`tel:${information.porterRanch.phone}`}>
                            <ButtonPrimary fill color="blue">
                                {' '}
                                <Mobile />
                                <span style={{ marginLeft: '11px' }}>PR - (818) 368-6266</span>
                            </ButtonPrimary>
                        </a>
                    </div>

                    <div className="item">
                        <p>New Hall</p>
                        <p>{information.newHall.location}</p>
                        <a href={`tel:${information.newHall.phone}`}>
                            <ButtonPrimary fill color="blue">
                                {' '}
                                <Mobile />
                                <span style={{ marginLeft: '11px' }}>
                                    NH - {information.newHall.phone}
                                </span>
                            </ButtonPrimary>
                        </a>
                    </div>
                </MobileCallContainer>
                <MobileNavContainer menuName={menuName} active={active}>
                    <div className="blue-line"></div>
                    {menuName && menuState && (
                        <button
                            className="back-button"
                            onClick={() => {
                                setMenuState(null);
                                setMenuName(null);
                            }}
                        >
                            <Arrow /> Back
                        </button>
                    )}
                    <p className="menu-name">{menuName}</p>
                    <ul className="main-menu-mobile">
                        {!menuName &&
                            navMenu.map(({ menu, name, path }) => {
                                if (menu) {
                                    return (
                                        <li key={name}>
                                            <button
                                                onClick={() => {
                                                    setMenuName(name);
                                                    setMenuState(menu);
                                                }}
                                            >
                                                {name} <Arrow />
                                            </button>
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li key={name}>
                                            <Link
                                                onClick={() => {
                                                    setActive(false);
                                                    setMenuName(null);
                                                    setMenuState(null);
                                                }}
                                                to={path}
                                            >
                                                {name}
                                            </Link>
                                        </li>
                                    );
                                }
                            })}
                        {menuName &&
                            menuState?.map(({ name, path }) => {
                                return (
                                    <li key={name}>
                                        <Link
                                            onClick={() => {
                                                setActive(false);
                                                setMenuName(null);
                                                setMenuState(null);
                                            }}
                                            to={path}
                                        >
                                            {name}
                                        </Link>
                                    </li>
                                );
                            })}
                    </ul>
                </MobileNavContainer>
                <DesktopMenuContainer scroll={scroll} className="" active={active}>
                    <ul className="main-menu">
                        {navMenu.map(({ menu, name, path }) => {
                            if (!menu) {
                                return (
                                    <li key={name}>
                                        <Link to={path}>{name}</Link>
                                    </li>
                                );
                            } else {
                                return (
                                    <li
                                        onMouseEnter={() => {
                                            setActive(true);
                                            setMenuName(name);
                                            setMenuState(menu);
                                        }}
                                        key={name}
                                    >
                                        <Link onClick={() => setActive(false)} to={path}>
                                            {name}
                                            <Arrow />
                                        </Link>
                                        {
                                            <DesktopMenu active={active} menuName={menuName}>
                                                {active &&
                                                    menuName === name &&
                                                    menuState &&
                                                    menuState.map(
                                                        (subItems: {
                                                            name: string;
                                                            path: string;
                                                            externalPath?: string;
                                                        }) => {
                                                            return (
                                                                <li key={subItems.name}>
                                                                    {subItems.path ? (
                                                                        <Link
                                                                            style={{}}
                                                                            onClick={() =>
                                                                                setActive(false)
                                                                            }
                                                                            to={subItems.path}
                                                                        >
                                                                            {subItems.name}
                                                                        </Link>
                                                                    ) : (
                                                                        <a
                                                                            href={
                                                                                subItems.externalPath
                                                                            }
                                                                            rel="noopener noreferrer"
                                                                            target="__blank"
                                                                        >
                                                                            {subItems.name}
                                                                        </a>
                                                                    )}
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                            </DesktopMenu>
                                        }
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </DesktopMenuContainer>
                <Link to="/">
                    <Logo aria-label="graphic link" className="logo" />
                </Link>
                <MobileIcons>
                    <button
                        style={{
                            position: 'relative',
                            bottom: '-3px',
                            background: 'none',
                            border: 'none',
                            padding: '0',
                        }}
                        onClick={() => {
                            setActive(false);
                            setCallActive(active => !active);
                        }}
                    >
                        <Mobile />
                    </button>
                    <Burger
                        active={active}
                        onClick={() => {
                            setCallActive(false);
                            setActive(active => !active);
                            setMenuName(null);
                            setMenuState(null);
                        }}
                    >
                        <div className="line-1"></div>
                        <div className="line-2"></div>
                        <div className="line-3"></div>
                    </Burger>
                </MobileIcons>
                <DesktopIcons>
                    <div className="desktop-button-container">
                        <ButtonPrimary color="blue">
                            {' '}
                            <Link to="/for-parents/appointments/">Request an appointment </Link>
                        </ButtonPrimary>
                        <button
                            ref={navRefCall}
                            onClick={() => setCallActive((active: boolean) => !active)}
                            className="mobile-button"
                        >
                            <Mobile className="mobile-svg" />
                            <DropdownArrow className="dropdown-svg" />
                            {callActive && (
                                <div className="mobile-button-menu">
                                    <ul>
                                        <li>
                                            <a
                                                className="map-link"
                                                target="__blank"
                                                rel="noopener noreferrer"
                                                href={`tel:${information.santaClarita.phone}`}
                                            >
                                                {`Santa Clarita - ${information.santaClarita.phone}`}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="map-link"
                                                target="__blank"
                                                rel="noopener noreferrer"
                                                href={`tel:${information.porterRanch.phone}`}
                                            >
                                                {`Porter Ranch - ${information.porterRanch.phone}`}
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                className="map-link"
                                                target="__blank"
                                                rel="noopener noreferrer"
                                                href={`tel:${information.newHall.phone}`}
                                            >
                                                {`New Hall - ${information.newHall.phone}`}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </button>
                    </div>
                </DesktopIcons>
            </NavWrapper>
        </NavContainer>
    );
};
